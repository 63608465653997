@font-face {
  font-family: LendingFont;
  src: url('./assets/font/Gotham-Medium.otf')
}

@font-face {
  font-family: LendingFont-light;
  src: url('./assets/font/Gotham-Light.otf')
}

body {
  font-family:
    LendingFont,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "PingFang SC",
    "Hiragino Sans GB",
    "Microsoft YaHei",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 16px !important;
  overflow-x: hidden;
  background-color: #010101;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none !important
}